import React from 'react';
import styled from 'styled-components';

import { mobile } from '../../styles/media-queries';
import Button from '../button';

const CardHeader = ({ plan, googleMessage, pageName }) => {
  const getMeterBarFillment = (plan, value) => {
    if (!value) {
      return false;
    }
    let internetTotal = parseInt(plan.internetTotal);
    let fillValue = parseInt(value);

    let fillPercentage = (fillValue / internetTotal) * 100;
    return `${fillPercentage}%`;
  };

  return (
    <Header>
      <InternetWrapper>
        <Internet>
          <Logo>TIM</Logo>
          <InternetTotal>
            {plan.internetTotal}
          </InternetTotal>

        </Internet>
        {/* {!plan.hasRestrictedBenefit && plan.hasDiscount && (
          <OldPrice>
            de R$<span>{plan.price}</span>/mês
          </OldPrice>
        )} */}
        <BluePrice>
          R$<strong> {plan.priceDiscount}</strong>/mês
        </BluePrice>
      </InternetWrapper>

      <BonusWrapper>
        <PlanMeter>
          <MeterHeader>
            <MeterText>Plano+Bônus</MeterText>
            <MeterText>
              {plan.internetTotal}&nbsp;<small> / {plan.internetTotal}</small>
            </MeterText>
          </MeterHeader>
          <MeterBar>
            <BarFiller />
            {plan.bonusDedicadoRedesSociais &&
              <BarFiller additionalBonus fillWidth={getMeterBarFillment(plan, plan.bonusDedicadoRedesSociais)} />}
            {plan.bonusC6Bank &&
              <BarFiller c6Bonus fillWidth={getMeterBarFillment(plan, plan.bonusC6Bank)} />}
            {plan.internetPortabilityBonus &&
              <BarFiller portabilityBonus fillWidth={getMeterBarFillment(plan, plan.internetPortabilityBonus)} />}
            {plan.internetAdditional &&
              <BarFiller additionalBonus fillWidth={getMeterBarFillment(plan, plan.internetAdditional)} />}
          </MeterBar>
          <MeterDescription>
            <MeterLabel>{plan.internet} para usar como quiser</MeterLabel>

            {plan.bonusDedicadoRedesSociais && <MeterLabel additionalBonus>{plan.bonusDedicadoRedesSociais} Exclusivos para redes sociais</MeterLabel>}
            {plan.bonusC6Bank && <MeterLabel c6Bonus>{plan.bonusC6Bank} Bônus C6 Bank</MeterLabel>}
            {plan.internetPortabilityBonus &&
              <MeterLabel portabilityBonus>{plan.internetPortabilityBonus} de Bônus especial</MeterLabel>}
            {plan.internetAdditional &&
              <MeterLabel additionalBonus>{plan.internetAdditional} de Bônus por tempo limitado</MeterLabel>}
          </MeterDescription>
        </PlanMeter>

        {plan.isLight ? (
          <Button
            className="card"
            planSku={plan.sku}
            planInternet={plan.internetTotal}
            openModal={() => MODAL.timPosUnificado({ sku: plan.sku, flow: 'light', googleMessage: googleMessage })}>
            Contratar Agora
          </Button>
        ) : (
          <Button
            className="card"
            planSku={plan.sku}
            planInternet={plan.internetTotal}
            openModal={() => MODAL.timPosUnificado({ sku: plan.sku, flow: pageName, googleMessage: googleMessage})}>
            Contratar Agora
          </Button>
        )}
      </BonusWrapper>
    </Header>
  );
};

const Header = styled.header`
  align-items: center;
  background-color: #002e46;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Logo = styled.h1`
  background: url('/images/logo-card.svg') no-repeat;
  background-size: contain;
  text-indent: -9999px;
  display: inline-block;
  width: 92px;
  height: 44px;
  ${mobile} {
    width: 100px;
  }
`;

const Text = styled.p`
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const Internet = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const InternetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 16px;
`;

const BonusWrapper = styled.div`
  background: #ffffff;
  padding: 15px;
  width: 100%;
`;

const Line = styled.div`
  box-sizing: border-box;
  background-color: #9b9b9b;
  height: 1px;
  margin: 4px 0;
  width: 90%;
`;

const PlanMeter = styled.div`
  width: 100%;
  margin: 0 0 15px;
`;

const MeterHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MeterText = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: #4a4a4a;

  small {
    font-size: 12px;
    align-self: flex-end;
    display: inline-block;
    margin-bottom: 3px;
  }
`;

const MeterBar = styled.div`
  position: relative;
  background: #cfcfcf;
  height: 7px;
  width: 100%;
  display: flex;
`;

const MeterDescription = styled.div`
  margin-top: 5px;
  line-height: 16px;
`;

const MeterLabel = styled.p`
  color: ${props => (props.isDisabled ? '#cfcfcf' : '#4a4a4a')};
  font-size: 12px;
  position: relative;

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #004691;
    display: inline-block;
    margin-right: 4px;
    ${props => props.portabilityBonus && 'background: #009fdf;'}
    ${props => props.additionalBonus && 'background: #93c90e;'}
    ${props => props.c6Bonus && 'background: #000;'}
  }
`;

const BarFiller = styled.div`
  background: #004691;
  height: 7px;
  ${props => props.c6Bonus && 'background: #000;'}
  ${props => props.portabilityBonus && 'background: #009fdf;'}
  ${props => props.additionalBonus && 'background: #93c90e;'}
  ${props => (props.fillWidth ? `width: ${props.fillWidth};` : 'flex: 1')};
`;

const TextNoBonusVideo = styled.p`
  color: white;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 10px;
  margin-top: 4px;
  max-width: 140px;
  padding-bottom: 26px;
  text-align: center;
`;

const InternetTotal = styled(Text)`
  font-size: 32px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 8px;
  width: 100%;

  img {
    position: absolute;
    top: 2px;
    right: 2px;
  }
`;

const Plus = styled.span`
  color: ${({ theme }) => theme.palette.secondary};
  font-weight: bold;
  font-size: 24px;
  float: left;
  line-height: 1;
`;

const Bonus = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  justify-content: center;
  margin: 0;
  text-align: center;
  width: 100%;

  &:before {
    content: '${({ text }) => text}';
    font-weight: lighter;
    font-size: 10px;
    line-height: 1;
  }

  > ${Text} {
    display: flex;
    align-items: center;
    font-size: 12px;

    > strong {
      font-size: 20px;
      margin: 0 4px 0 0;
    }
  }
`;

const OldPrice = styled.p`
  color: ${({ theme }) => theme.palette.lightBlue};
  font-size: 16px;
  height: 17px;

  > span {
    text-decoration: line-through;
  }
`;

const BluePrice = styled.p`
  color: ${({ theme }) => theme.palette.lightBlue};
  text-align: left;
  font-size: 16px;
  width: 100%;

  strong {
    font-size: 32px;
  }
`;

export default CardHeader;
